import React from "react"
import Layout from "../../components/Layout"
import Hero from "../../components/Hero"
import Rates from "./components/RateSection"
import CTA from "./components/CTA"
import { graphql, useStaticQuery } from "gatsby"

const RatesPage = () => {
  const query = useStaticQuery(graphql`
    query {
      contentfulPageData(slug: { eq: "rates" }) {
        id
        title
        conclusion {
          conclusion
        }
        pageHeader {
          file {
            url
          }
          fluid(quality: 100, toFormat: WEBP) {
            src
          }
        }
      }

      allContentfulServicePackage(filter: { category: { eq: "general" } }) {
        nodes {
          id
          title
          about {
            about
          }
          image {
            fluid(quality: 100, toFormat: WEBP) {
              src
            }
          }

          features {
            raw
          }

          feature1
          feature2
          feature3
        }
      }
    }
  `)
  const {
    contentfulPageData: data,
    allContentfulServicePackage: { nodes: serviceList },
  } = query

  let defaultImage = {
    file: { url: "/img/featured/banner.jpg", alt: "background placeholder" },
  }
  let ctaContent
  if (data) {
    defaultImage = {
      file: {
        url: data.pageHeader.file.url,
      },
    }
    ctaContent = data.conclusion.conclusion
  }

  return (
    <Layout>
      <Hero title="RATES" heroImage={defaultImage} />
      <Rates serviceList={serviceList} />
      {ctaContent && <CTA content={ctaContent} />}
    </Layout>
  )
}

export default RatesPage
