import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

const CTA = ({ content }) => {
  return (
    <section className="pt64 pb64">
      <div className="container">
        <hr />
        <div className="row">
          <div className="col-sm-12 text-center">
            <h4 className="mb8">{content}</h4>

            <Link className="btn btn-filled btn-lg mb4" to="/contact">
              CONTACT ME
            </Link>
          </div>
        </div>
        <hr />
      </div>
    </section>
  )
}

CTA.propTypes = {
  content: PropTypes.string,
}
export default CTA
